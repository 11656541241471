import React from "react";
import { Link } from "gatsby";
import _get from "lodash.get";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";

import Button from "app/components/Button";
import { ButtonBase } from "app/components/Button.style";
import LogoTransfigurations from "app/components/icons/LogoTransfigurations";
import HeaderDropdown from "app/components/HeaderDropdown";
import IconClose from "app/components/icons/IconClose";
import IconGallery from "app/components/icons/IconGallery";
import IconExhibition from "app/components/icons/IconExhibition";
import RedactorText from "app/components/RedactorText";

import useNavData from "app/hooks/useNav";
import useSitePages from "app/hooks/useSitePages";

import * as S from "app/components/Header.style";

const HeaderButton = ({ label, Icon, ...props }) => (
  <Button css={S.button} {...props}>
    {label} <Icon css={S.icon} />
  </Button>
);

const HeaderNav = () => {
  const { pathname } = useLocation();
  const galleryMatch = pathname.indexOf("/galleries") >= 0;
  const { baseUri, sitePages, currentExhibition } = useSitePages();
  const homeLabel = _get(currentExhibition, "homeLabel") || "Home";

  if (!baseUri) {
    return null;
  }

  return (
    <>
      <HeaderDropdown pages={sitePages} />
      <nav css={S.mlauto} data-track-name="header-nav">
        <Button css={S.button} to={`/${baseUri}`}>
          {homeLabel}
          <RedactorText
            html={_get(currentExhibition, "homeIcon[0].imageFile.svg.content")}
            css={S.homeIcon}
          />
        </Button>
        <HeaderButton
          label={"Gallery"}
          to={`/${baseUri}/gallery`}
          Icon={IconGallery}
          className={galleryMatch ? "active" : ""}
        />
        <HeaderButton
          label={"About Transfigurations"}
          to={`/${baseUri}/about`}
          Icon={IconExhibition}
        />
      </nav>
    </>
  );
};

const Header = ({
  baseUri,
  onBack,
  label,
  className,
  initial,
  animate,
  transition,
  variants,
}) => {
  const { backLink } = useNavData();
  const { baseUri: siteBaseUri } = useSitePages();

  return (
    <header css={S.sticky}>
      <div css={[S.container, S.mxauto]}>
        <motion.div
          css={[S.w100, S.wrap]}
          className={className}
          variants={variants}
          initial={initial}
          animate={animate}
          transition={transition}
        >
          <h1 css={S.my0}>
            <Link to={`/${siteBaseUri || ""}`}>
              <LogoTransfigurations css={S.logo} />
            </Link>
          </h1>
          {!backLink && !onBack && <HeaderNav baseUri={baseUri} />}
          {backLink && (
            <nav css={S.mlauto}>
              <Button Component={Link} to={_get(backLink, "uri")}>
                <span css={S.galleryText}>
                  {_get(backLink, "label", "Back to Gallery")}
                </span>
                <IconClose css={S.closeIcon} />
              </Button>
            </nav>
          )}
          {onBack && (
            <nav css={S.mlauto}>
              <Button Component={ButtonBase} onClick={onBack}>
                <span css={S.galleryText}>{label}</span>
                <IconClose css={S.closeIcon} />
              </Button>
            </nav>
          )}
        </motion.div>
      </div>
    </header>
  );
};

export default Header;
