import { css } from "@emotion/react";
// import styled from "@emotion/styled";

export { relative, border, borderRound } from "app/styles/utils";

export const popup = css`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 310px;
  z-index: 2;
`;

export const popupBox = css`
  display: block;
  width: 100%;
  padding: 20px;
  padding-right: 40px;
  background: var(--graydark);
`;

export const inlineOffset = css`
  transform: translate3d(0.15em, -0.33em, 0);
`;

export const spanBlock = css`
  display: block;

  * + & {
    margin-top: 20px;
  }
`;
