import { useState, useRef, useEffect } from "react";
import { useClickAway, useScrollbarWidth, useWindowSize } from "react-use";

const usePopupRef = () => {
  const ref = useRef(null);
  const sbw = useScrollbarWidth();
  const [offset, setOffset] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const { width } = useWindowSize();
  const close = () => setOpen(false);

  useEffect(() => {
    if (isOpen && ref.current) {
      const bounds = ref.current.getBoundingClientRect();
      const overflow = bounds.right + sbw + 30 - width;
      if (overflow > 0) {
        setOffset(overflow + 30);
      }
    }
  }, [isOpen, width, sbw, setOffset]);

  useClickAway(ref, close);

  return {
    ref,
    offset,
    isOpen,
    setOpen,
  };
};

export default usePopupRef;
