import React, { useState, useContext } from "react";
import { useLocation } from "@reach/router";

export const NavContext = React.createContext();

export const NavProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [navItems, setNavItems] = useState([]);
  const [backLink, setBackLink] = useState();

  const state = {
    backLink,
    setBackLink,
    pathname,
    navItems,
    setNavItems,
  };

  return <NavContext.Provider value={state}>{children}</NavContext.Provider>;
};

const useNavData = () => useContext(NavContext);

export default useNavData;
