/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import PersistentLayout from "app/wrapPageElement";

export const wrapPageElement = PersistentLayout;

// const reloadPrompt = `This application has been updated. Reload to display the latest version?`;

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(reloadPrompt);
//   if (answer === true) {
//     window.location.reload(true);
//   }
// };
