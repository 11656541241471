import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { container } from "app/styles/utils";

export const Artboard = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: var(--row-gap);
  grid-template-columns: 270px minmax(30px, 5%) 1fr;
  grid-template-areas: "nav . content";
  ${container};
  margin: 0 auto;
  margin-top: var(--nav-height);
  /* overflow-x: hidden; */
`;

export const header = css`
  grid-area: header;
`;

export const nav = css`
  grid-area: nav;
`;

export const content = css`
  grid-area: content;
`;
