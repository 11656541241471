// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-about-page-js": () => import("./../../../src/page-templates/about-page.js" /* webpackChunkName: "component---src-page-templates-about-page-js" */),
  "component---src-page-templates-detail-page-js": () => import("./../../../src/page-templates/detail-page.js" /* webpackChunkName: "component---src-page-templates-detail-page-js" */),
  "component---src-page-templates-exhibition-js": () => import("./../../../src/page-templates/exhibition.js" /* webpackChunkName: "component---src-page-templates-exhibition-js" */),
  "component---src-page-templates-gallery-item-page-js": () => import("./../../../src/page-templates/gallery-item-page.js" /* webpackChunkName: "component---src-page-templates-gallery-item-page-js" */),
  "component---src-page-templates-gallery-page-js": () => import("./../../../src/page-templates/gallery-page.js" /* webpackChunkName: "component---src-page-templates-gallery-page-js" */),
  "component---src-page-templates-preview-subpage-js": () => import("./../../../src/page-templates/preview-subpage.js" /* webpackChunkName: "component---src-page-templates-preview-subpage-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

