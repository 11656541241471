import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconGallery = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M8.16 18.447c1.64 0 2.22-.58 2.22-2.221v-4.444c0-1.64-.58-2.221-2.22-2.221H3.715c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.995 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.996 0c1.641 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.996 0c1.641 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.58-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zM8.296 34.443c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221H3.853c-1.641 0-2.222.58-2.222 2.221v4.444c0 1.64.58 2.221 2.222 2.221h4.443zm15.996 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.641 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.996 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.996 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zM8.296 50.44c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221H3.853c-1.641 0-2.222.58-2.222 2.221v4.444c0 1.64.58 2.221 2.222 2.221h4.443zm15.996 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.641 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.996 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443zm15.996 0c1.64 0 2.222-.58 2.222-2.221v-4.444c0-1.64-.581-2.221-2.222-2.221h-4.443c-1.64 0-2.222.58-2.222 2.221v4.444c0 1.64.581 2.221 2.222 2.221h4.443z"
    ></path>
  </SVGIcon>
);

IconGallery.defaultProps = {
  width: "20",
  height: "20",
  viewBox: "0 0 60 60",
  color: "currentColor",
};

export default IconGallery;
