import React, { useContext } from "react";

import { blur } from "app/components/Button";
import IconClose from "app/components/icons/IconClose";
import { InfoBoxContext } from "app/components/infoboxes/InfoboxProvider";

import * as S from "app/components/infoboxes/Infobox.style";

export const CloseButton = (props) => (
  <S.CloseBtn onMouseDown={blur} {...props}>
    <IconClose css={S.closeBtnIcon} />
  </S.CloseBtn>
);

const InfoBox = ({ children, html, Popup, top, left }) => {
  const { ref, setOpen } = useContext(InfoBoxContext);

  return (
    <Popup ref={ref} top={top} left={left}>
      {!html && children}
      {html}
      <CloseButton onClick={() => setOpen(false)} onMouseDown={blur} />
    </Popup>
  );
};

InfoBox.defaultProps = {
  Popup: S.Popup,
};

export default InfoBox;
