import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconChevron = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M30.857 45.088c.752 0 1.504-.274 2.017-.855L53.86 23.11a2.76 2.76 0 00.82-1.982c0-1.607-1.196-2.837-2.802-2.837-.752 0-1.504.342-2.017.82L30.857 38.218 11.82 19.11c-.513-.478-1.196-.82-1.982-.82C8.23 18.291 7 19.521 7 21.128c0 .786.307 1.47.82 1.982l21.02 21.123c.547.581 1.231.855 2.017.855z"
    />
  </SVGIcon>
);

IconChevron.defaultProps = {
  color: "currentColor",
  width: "20",
  height: "20",
  viewBox: "0 0 60 60",
};

export default IconChevron;
