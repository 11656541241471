import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fadeIn } from "app/styles/animations";

export const Button = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 50px 135px;
  border: 1px solid var(--ivory);
  border-radius: 6px;
  font-size: 33px;
  line-height: 1.3;
  text-align: center;
  pointer-events: none;
  color: var(--ivory);
  z-index: 2;
`;

export const coverScreen = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

export const outerbg = css`
  position: fixed;
  display: block;
  background-color: var(--graydark);
  opacity: 0.8;
`;

export const innerbg = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--graydark);
  opacity: 0.8;
  z-index: -1;
`;

export const overlay = css`
  display: block;
  z-index: 9999;
  ${fadeIn};
`;
