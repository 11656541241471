import React from "react";
import Layout from "app/components/Layout";

const wrapPageElement = ({ element, props }) => (
  <>
    <Layout {...props}>{element}</Layout>
    <div id="app-portal" key="app-portal" />
  </>
);

export default wrapPageElement;
