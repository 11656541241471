import React from "react";
// import _get from "lodash.get";

import BaseCSS from "app/styles/global";
import NormalizeCSS from "app/styles/normalize";

import Header from "app/components/Header";
import InfoBoxProvider from "app/components/infoboxes/InfoboxProvider";
import IdleScreen from "app/components/IdleScreen";
import SideNav from "app/components/SideNav";

import { NavProvider } from "app/hooks/useNav";
import { PageContextProvider } from "app/hooks/useSitePages";

import * as S from "app/components/Layout.style";

const Layout = ({ children, pageContext }) => (
  <InfoBoxProvider>
    <NormalizeCSS />
    <BaseCSS />
    <NavProvider>
      <PageContextProvider pageContext={pageContext}>
        <S.Artboard>
          <Header />
          <SideNav />
          {children}
        </S.Artboard>
        <IdleScreen />
      </PageContextProvider>
    </NavProvider>
  </InfoBoxProvider>
);

export default Layout;
