import React from "react";
// import { useWindowSize } from "react-use";
// import _get from "lodash.get";

import { CloseButton } from "app/components/infoboxes/Infobox";
import InfoButton from "app/components/infoboxes/InfoButton";

import usePopupRef from "app/components/infoboxes/usePopupRef";

import * as S from "app/components/infoboxes/InfoPopup.style";

export const InfoPopup = ({ children, className, wrapperClass, label }) => {
  const { ref, setOpen, isOpen, offset } = usePopupRef();

  return (
    <span css={S.relative}>
      <InfoButton
        className={className}
        label={label}
        onClick={() => setOpen(true)}
      />
      {isOpen && (
        <span ref={ref} css={[S.popup]}>
          <span
            css={[S.border, S.borderRound, S.popupBox, wrapperClass]}
            style={
              Math.floor(offset) > 0
                ? { transform: `translateX(${Math.floor(offset) * -1}px)` }
                : undefined
            }
          >
            {children}
            <CloseButton onClick={() => setOpen(false)} />
          </span>
        </span>
      )}
    </span>
  );
};

export default InfoPopup;
