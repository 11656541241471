import styled from "@emotion/styled";
import { css } from "@emotion/react";

import {
  baseText,
  weightThin,
  fontSize,
  fontWeights,
  rem,
} from "app/styles/utils";

export const h1 = css`
  font-size: 66px;
  line-height: 1.2;
  font-weight: ${fontWeights.normal};
  letter-spacing: ${rem(-4.8)};
  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;
`;

export const h2 = css`
  ${fontSize(70)}
  font-weight: ${fontWeights.medium};
  letter-spacing: ${rem(-1.68)};
  margin-top: 0;
  margin-bottom: 0;
`;

export const body = css`
  ${baseText};
  ${weightThin};
  font-size: 20px;
  letter-spacing: 0;
  margin: 0;

  * + & {
    margin-top: 21px;
  }
`;

export const li = css`
  position: relative;
  ${baseText};
  ${weightThin};
  font-size: 20px;
  margin: 0;

  * + & {
    margin-top: 21px;
  }
`;

export const ul = css`
  margin: 22px 0;
  padding: 0;
  list-style: none;
  ${weightThin}

  li {
    padding-left: 16px;

    &::before {
      content: "•";
      position: absolute;
      top: 0.5em;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;

export const ol = css`
  margin: 22px 0;
  padding: 0;
  padding-left: 16px;
`;

export const citation = css`
  font-size: 16px;
  line-height: ${23 / 15};
  font-weight: ${fontWeights.thin};
  margin-top: 0;
  margin-bottom: 0;
  overflow-wrap: break-word;
  ${"" /* word-break: break-all; */}

  * + & {
    margin-top: 16px;
  }
`;

export const galleryTitle = css`
  ${fontSize(100, 130)}
  font-weight: ${fontWeights.normal};
  letter-spacing: ${rem(-2.4)};
  white-space: pre-line;
`;

export const subheading = css`
  ${fontSize(50, 90)};
`;

export const blockquote = css`
  ${body};
  font-style: italic;
  padding: 40px 0 40px 40px;
`;

const variants = {
  h1,
  h2,
  ul,
  ol,
  li,
  body,
  blockquote,
  citation,
  galleryTitle,
};

export const Typography = styled.p`
  ${({ variant }) => variants[variant] || ``};
  ${({ weight }) =>
    (fontWeights[weight] && `font-weight: ${fontWeights[weight]};`) || ``};

  ${({ m0 }) => (m0 && `margin:0`) || ``};
`;
