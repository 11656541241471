import React from "react";
import { Link } from "gatsby";

import * as S from "app/components/Button.style";

export const blur = (e) => {
  e.preventDefault();
  e.target.blur();
};

const Btn = ({
  Component,
  onMouseDown,
  label,
  Icon,
  children,
  invert,
  outlined,
  ...props
}) => {
  return (
    <Component
      onMouseDown={(e) => {
        blur(e);
        onMouseDown && onMouseDown(e);
      }}
      css={S.btnStyles({ invert, outlined })}
      activeClassName="active"
      {...props}
    >
      {children}
      {!children && (
        <>
          <span css={S.textStyles}>{label}</span>
          {Icon &&
            React.cloneElement(Icon, {
              css: S.icon,
            })}
        </>
      )}
    </Component>
  );
};

Btn.defaultProps = {
  Component: Link,
  variant: "text",
};

const WrappedButton = ({ center, ...props }) =>
  center ? (
    <S.CenterWrap>
      <Btn {...props} />
    </S.CenterWrap>
  ) : (
    <Btn {...props} />
  );

export default WrappedButton;
