import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights } from "app/styles/utils";

export const icon = css`
  height: ${rem(84)};
  width: ${rem(84)};
  margin-left: ${rem(30)};
`;

export const text = css`
  background: transparent;
  color: var(--ivory);
`;

export const btnText = css`
  ${fontSize(70, 84)}
  font-weight: ${fontWeights.normal};
  letter-spacing: 0;
`;

export const textStyles = css`
  ${btnText};

  & > .active {
    font-weight: ${fontWeights.normal};
  }
`;

export const outlinedStyle = css`
  border: 1px solid var(--ivory);
`;

export const invertColors = css`
  background-color: var(--ivory);
  color: var(--graydark);

  &:focus,
  &:active,
  &:hover {
    color: var(--graydark);
  }
`;

export const btn = css`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${rem(40)} ${rem(80)};
  border: 0;
  border-radius: ${rem(20)};
  cursor: pointer;
  background: transparent;
  border: 0;
  color: inherit;

  &:focus,
  &:active,
  &:hover {
    color: var(--ivory);
  }
`;

export const btnStyles = ({ outlined, invert }) => css`
  ${btn};
  ${invert && invertColors};
  ${outlined && outlinedStyle};

  &.active {
    ${invertColors};
  }
`;

export const CenterWrap = styled.div`
  margin: auto;
  overflow: hidden;
  text-align: center;
`;

export const ButtonBase = styled.button``;
