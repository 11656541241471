import React from "react";
import _get from "lodash.get";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
} from "react-html-parser";

import {
  inlineOffset,
  spanBlock,
} from "app/components/infoboxes/InfoPopup.style";
import InfoPopup from "app/components/infoboxes/InfoPopup";
import Typography from "app/components/Typography";

const elements = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "ol",
  "ul",
  "li",
  "blockquote",
];

const footnoteTransform = (node, idx) => {
  if (node.type === "tag" && node.name === "p") {
    return (
      <span css={spanBlock} key={`${node.type}--${idx}`}>
        {processNodes(node.children, footnoteTransform)}
      </span>
    );
  }
};

const parseFootnote = (html) =>
  ReactHtmlParser(html, {
    decodeEntities: true,
    transform: footnoteTransform,
  });

const RedactorText = ({ html, infoBoxes, className }) => {
  const transform = (node, idx) => {
    const classes = _get(node, "attribs.class", "");

    if (node.type === "text" && !node.parent) {
      return (
        <Typography key={`${node.type}--${idx}`} variant={"body"}>
          {node.data}
        </Typography>
      );
    }

    if (node.type === "tag" && node.name === "svg") {
      return (
        <svg
          key={`${node.type}--${idx}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={_get(node, "attribs.viewbox", "")}
          className={className}
        >
          {processNodes(node.children, transform)}
        </svg>
      );
    }

    if (
      node.type === "tag" &&
      node.name === "span" &&
      classes.includes("fn-marker")
    ) {
      const footnoteIdx = parseInt(_get(node, "children[0].data", -1), 10);
      const footNote = infoBoxes.find(
        ({ sortOrder }) => sortOrder === footnoteIdx
      );

      return footNote ? (
        <InfoPopup key={`fn-marker--${idx}`} css={inlineOffset}>
          {parseFootnote(_get(footNote, "footnoteText"))}
        </InfoPopup>
      ) : (
        <React.Fragment key={`fn-marker--${idx}`}></React.Fragment>
      );
    }

    if (node.type === "tag" && node.name === "p") {
      return (
        <Typography key={`${node.type}--${idx}`} variant={"body"}>
          {processNodes(node.children, transform)}
        </Typography>
      );
    }

    if (
      node.type === "tag" &&
      elements.findIndex((type) => type === node.name) !== -1
    ) {
      return (
        <Typography key={`${node.type}--${idx}`} variant={node.name}>
          {processNodes(node.children, transform)}
        </Typography>
      );
    }
  };

  if (!html) {
    return <></>;
  }

  return (
    <>
      {ReactHtmlParser(html, {
        decodeEntities: true,
        transform,
      })}
    </>
  );
};

export default RedactorText;

const allowedTags = ["b", "span", "cite", "em", "strong", "u", "mark"];
const simpleTransform = (node, index) => {
  if (node.type === "tag" && !allowedTags.includes(node.name)) {
    node.name = "span";
  }
  return convertNodeToElement(node, index, simpleTransform);
};

export const RedactorSimpleText = ({ html }) => (
  <>
    {ReactHtmlParser(html, {
      decodeEntities: true,
      transform: simpleTransform,
    })}
  </>
);
