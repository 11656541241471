import React, { memo } from "react";

export const SVGIcon = ({ children, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    {children}
  </svg>
);

SVGIcon.defaultProps = {
  width: "84",
  height: "84",
  viewBox: "0 0 84 84",
};

export default memo(SVGIcon);
