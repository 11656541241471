import React from "react";

import * as S from "app/components/Typography.style";

const variants = {
  h1: "h1",
  galleryTitle: "h1",
  h2: "h2",
  subhead: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  body: "p",
  blockquote: "p",
  citation: "p",
  ol: "ol",
  ul: "ul",
  li: "li",
};

const Typography = React.forwardRef(({ variant, as, ...props }, ref) => (
  <S.Typography
    ref={ref}
    as={as || variants[variant]}
    variant={variant}
    {...props}
  />
));

export default Typography;
