import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconInfo = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule={"nonzero"}
      d="M1.943 1.988c.435 0 .77-.328.765-.74 0-.42-.33-.748-.765-.748a.75.75 0 00-.766.749c0 .41.34.739.766.739zM3.41 7.5c.355 0 .584-.203.589-.522 0-.324-.23-.527-.589-.527H2.69V3.443c0-.332-.225-.54-.565-.54H.713c-.359 0-.589.203-.589.531.005.319.235.522.589.522h.818v2.495H.59c-.364 0-.589.203-.589.527.005.319.234.522.589.522H3.41z"
    ></path>
  </SVGIcon>
);

IconInfo.defaultProps = {
  color: "currentColor",
  width: "4",
  height: "8",
  viewBox: "0 0 4 8",
};

export default IconInfo;
