import { css } from "@emotion/react";

export {
  alignCenter,
  z100,
  w100,
  container,
  mxauto,
  mlauto,
  my0,
  flexInline,
} from "app/styles/utils";

export const logo = css`
  width: auto;
  height: 50px;
`;

export const wrap = css`
  display: flex;
  align-items: center;
  padding: 24px 26px;
  height: var(--nav-height);
  background-color: var(--graydark);
  border: 1px solid var(--ivory);
  border-radius: 0 0 22px 22px;
  border-top: 0;
  margin: 0 auto;
`;

export const sticky = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--graydark);
  z-index: 500;
`;

export const icon = css`
  width: 30px;
  height: auto;
  margin-left: 8px;
`;

export const homeIcon = css`
  width: 20px;
  height: auto;
  margin-left: 8px;
`;

export const button = css`
  font-size: 24px;
  line-height: 1;
`;

export const galleryText = css`
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0;
`;

export const closeIcon = css`
  margin: 0 -7px;
  width: 56px;
  height: 56px;
  position: relative;
  top: 2px;
`;
