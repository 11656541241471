import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconClose = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M52.935 51.838a1.116 1.116 0 000-1.53l-8.982-8.982 8.982-8.965c.404-.422.422-1.125 0-1.529a1.082 1.082 0 00-1.53 0l-8.982 8.965-8.965-8.965c-.422-.422-1.125-.422-1.529 0a1.082 1.082 0 000 1.53l8.982 8.964-8.982 8.983a1.082 1.082 0 000 1.529 1.116 1.116 0 001.53 0l8.964-8.983 8.983 8.983c.422.404 1.107.422 1.529 0z"
    ></path>
  </SVGIcon>
);

IconClose.defaultProps = {
  color: "currentColor",
};

export default IconClose;
