import { css } from "@emotion/react";

export { absolute, flex, flexCol, relative } from "app/styles/utils";

export const mx80 = css`
  margin-left: 80px;
  margin-right: 80px;
`;

export const btnFont = css`
  font-size: 24px;
  line-height: 1.2;
`;

export const popupFont = css`
  font-size: 18px;
  line-height: 1.2;
`;

export const popup = css`
  top: 100%;
  left: 0;
  background-color: var(--ivory);
  width: max-content;
`;
