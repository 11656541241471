import React from "react";
import { css, Global } from "@emotion/react";

import { baseFont, fontSize, ivory, gray1F, hexToRGBA } from "./utils";

import "typeface-roboto";

const font = css`
  html {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -ms-content-zooming: none;
    touch-action: pan-x pan-y;
    font-family: ${baseFont};
    color: ${ivory};
    background: ${gray1F};
    box-sizing: border-box;
    scroll-behavior: smooth;

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  :root {
    --scroll-track: ${hexToRGBA(ivory, 0.02)};
    --graydark: ${gray1F};
    --ivory: ${ivory};

    --nav-height: 100px;

    --container-max-width: 2000px;
    --container-min-width: 768px;
    --gutter: 30px;

    --row-gap: 60px;
    --top-gap: 0;
  }

  *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  *::-webkit-scrollbar {
    width: 6px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--ivory);
  }

  p {
    ${fontSize(50, 80)};
    letter-spacing: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

const links = css`
  a,
  a:active,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
  }
`;

const SiteGlobal = () => (
  <Global
    styles={css`
      ${font};
      ${links};
    `}
  />
);

export default SiteGlobal;
