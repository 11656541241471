import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "app/styles/utils";
import { fadeIn } from "app/styles/animations";

export {
  alignCenter,
  border,
  borderRound,
  flex,
  flexInline,
  flexCenterAll,
  mt0,
  relative,
} from "app/styles/utils";

export const iconBox = css`
  height: 18px;
  width: 18px;
`;

export const label = css`
  font-size: 16px;
  line-height: 1.2;
  padding-right: 9px;
`;

export const hitbox = css`
  opacity: 0;
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  z-index: 1;
  cursor: pointer;
`;

export const Popup = styled.span`
  position: fixed;
  ${({ top }) => `top: ${top}`};
  ${({ left }) => `left: ${left}`};
  transform: translate3d(-50%, 0, 0);
  display: block;
  width: ${rem(933)};
  padding: ${rem(42)} ${rem(110)} ${rem(42)} 20px;
  background: var(--graydark);
  border: 1px solid var(--ivory);
  background: var(--graydark);
  box-shadow: ${rem(20)} ${rem(20)} ${rem(50)} 0 var(--graydark);
  border-radius: ${rem(20)};
  ${fadeIn};
  z-index: 500;
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: ${rem(20)};
  right: ${rem(20)};
  padding: 0;
  background: none;
  color: var(--ivory);
  border: 0;
  cursor: pointer;
`;

export const closeBtnIcon = css`
  display: block;
  width: ${rem(120)};
  height: ${rem(120)};
`;

export const textBox = css`
  display: block;
`;

export const spacer = css`
  padding-left: 0.5ch;
`;
