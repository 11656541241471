import React from "react";
import SVGIcon from "app/components/icons/SVGIcon";

const IconExhibition = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M69.358 12.434a2.292 2.292 0 012.288 2.292v54.63a2.29 2.29 0 01-2.288 2.289h-5.132v-4.58h2.92V16.934h-8.764v11.528a2.29 2.29 0 01-2.288 2.288H30.198V54.2l9.474-.004a2.29 2.29 0 010 4.577H27.91a2.29 2.29 0 01-2.288-2.288V28.539a2.29 2.29 0 012.288-2.288h25.815v-9.236H29.49v-4.58zm-48.32-.079v4.58h-4.105v50.131h30.633V44.33h-9.393a2.29 2.29 0 010-4.577H59.17c1.265 0 2.291 1.027 2.211 2.292a2.29 2.29 0 01-2.288 2.288h-7.105l-.003 22.65h4.262v4.58H14.642a2.29 2.29 0 01-2.288-2.288v-54.63a2.29 2.29 0 012.288-2.289z"
    ></path>
  </SVGIcon>
);

IconExhibition.defaultProps = {
  color: "currentColor",
  width: "20",
  height: "20",
  viewBox: "0 0 84 84",
};

export default IconExhibition;
