import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useIdle, useInterval } from "react-use";
import * as S from "app/components/IdleScreen.style";
import { Typography } from "./Typography.style";
import { navigate } from "gatsby";
import { useWindowScroll } from "react-use";
import _get from "lodash.get";

import useSitePages, { usePageContext } from "app/hooks/useSitePages";

const Countdown = () => {
  const { pathname } = useLocation();
  const { baseUri } = usePageContext();
  const { y } = useWindowScroll();
  const [count, setCount] = React.useState(15);
  const countingDown = count > 0;

  const basePath = `/${baseUri || ""}`;

  useInterval(
    () => {
      setCount(count - 1);
    },
    count > 0 ? 1000 : null
  );

  useEffect(() => {
    if (!countingDown) {
      if (pathname !== basePath) {
        navigate(`/${baseUri}`);
      }
      if (y !== 0) {
        window.scrollTo(0, 0);
      }
    }
  }, [countingDown, basePath, pathname, y]);

  return (
    <div css={[S.overlay, S.coverScreen]}>
      <style
        dangerouslySetInnerHTML={{
          __html: "html { scroll-behavior: initial; }",
        }}
      />
      <div css={[S.coverScreen, S.outerbg]} />
      <S.Button>
        <div css={[S.innerbg]} />
        <Typography variant="galleryTitle">Are you still there?</Typography>
        <Typography variant="h1">{count}</Typography>
        <Typography variant="galleryTitle">
          Touch anywhere to continue.
        </Typography>
      </S.Button>
    </div>
  );
};

const IdleTimeOut = ({ timeout, idleAfterMs, pollSeconds }) => {
  const [sessionTime, setSessionTime] = React.useState(0);
  const isIdle = useIdle(idleAfterMs);

  useInterval(() => {
    setSessionTime(sessionTime + pollSeconds);
  }, pollSeconds * 1000);

  useEffect(() => {
    console.log("sessionTime", sessionTime);
  }, [sessionTime]);

  useEffect(() => {
    if (!isIdle) {
      console.log("reset session");
      setSessionTime(0);
    }
  }, [isIdle]);

  return isIdle && sessionTime >= timeout ? <Countdown /> : <></>;
};

IdleTimeOut.defaultProps = {
  pollSeconds: 5,
  idleAfterMs: 5 * 1000,
  timeout: 1 * 60,
};

const IdleScreen = () => {
  const { pathname } = useLocation();
  const { baseUri } = usePageContext();
  const { currentExhibition } = useSitePages();
  const { y } = useWindowScroll();

  const disable =
    (pathname === `/${baseUri || ""}` && y === 0) ||
    _get(currentExhibition, "idleTimeout") !== true;

  return disable ? null : <IdleTimeOut pathname={pathname} />;
};

export default IdleScreen;
