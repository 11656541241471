import { css } from "@emotion/react";

export {
  block,
  baseText,
  baseList,
  border,
  borderRound,
  iconSquare,
  flex,
  flexCol,
  alignStart,
  justifyStart,
  pinAll,
  pinTopGap,
  pTopGap,
  mlauto,
  mtauto,
  w100,
  textButton,
  relative,
  weightBold,
} from "app/styles/utils";

export const navRoot = css`
  --nav-gap: 13px;
`;

export const navHeight = css`
  height: calc(100vh - var(--nav-height) - 40px);
`;

export const sticky = css`
  position: sticky;
`;

export const navItem = css`
  padding: 9px var(--nav-gap);
  padding-left: calc(var(--nav-gap) * 2);
  margin-bottom: var(--nav-gap);
`;

export const subNavItem = css`
  padding-left: calc(var(--nav-gap) * 4);
`;

export const activeItem = css`
  height: 100%;
  width: 100%;
  transform-origin: 50% 50% 0 !important;
`;
