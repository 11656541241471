import { css, keyframes } from "@emotion/react";

const baseAnimate = css`
  animation-fill-mode: both;
  animation-duration: 0.5s;
`;

const fadeInAnim = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeInUpAnim = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInDownAnim = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fadeIn = css`
  ${baseAnimate};
  animation-name: ${fadeInAnim};
`;

export const fadeInDown = css`
  ${baseAnimate};
  animation-name: ${fadeInDownAnim};
`;

export const fadeInUp = css`
  ${baseAnimate};
  animation-name: ${fadeInUpAnim};
`;

export const breatheAnim = keyframes`
  0% {
    transform: scale(1) translateZ(0);
  }

  100% {
    transform: scale(1.5) translateZ(0);
  }
`;

export const breatheInOut = css`
  animation-fill-mode: both;
  animation-duration: 2.5s;
  animation-direction: alternate;
  animation-name: ${breatheAnim};
  animation-iteration-count: infinite;
`;
