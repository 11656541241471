import React from "react";
import _get from "lodash.get";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import _getArray from "app/utils/_getArray";

import { AnimateSharedLayout, motion } from "framer-motion";
import { blur } from "app/components/Button";

import useNavData from "app/hooks/useNav";

import * as S from "app/components/SideNav.style";

export const PageContext = React.createContext(null);

const subNavVariants = {
  open: {
    height: "auto",
  },
  closed: {
    height: 0,
    pointerEvents: "none",
  },
  transition: { ease: "easeIn" },
};

const subItemVariants = {
  open: {
    opacity: 1,
    y: 0,
  },
  closed: {
    opacity: 0,
    y: -20,
  },
};

const ActiveMarker = ({ subItem }) => (
  <motion.svg
    css={[S.pinAll, S.activeItem]}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 269 47"
    layoutId="outline"
    className="outline"
    initial={false}
    preserveAspectRatio="none"
    transition={{
      ease: "easeInOut",
      when: "beforeChildren",
      duration: 0.5,
      delayChildren: 0.3,
    }}
  >
    <path
      fill="currentColor"
      d="M266.306 0a3 3 0 013 3v40.662a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h263.306zm0 .5H3A2.5 2.5 0 00.505 2.836L.5 3v40.662a2.5 2.5 0 002.336 2.495l.164.005h263.306a2.5 2.5 0 002.495-2.336l.005-.164V3a2.5 2.5 0 00-2.335-2.495L266.306.5z"
      fillRule="nonzero"
    />
  </motion.svg>
);

const NavButton = (props) => <button {...props} />;
const NavLink = ({ uri, ...props }) => <Link to={`/${uri}/`} {...props} />;

const ParentIcon = ({ className, expanded }) => (
  <svg viewBox="0 0 60 60" className={className}>
    <g fill="currentColor" fillRule="nonzero">
      <motion.path
        d="M30 45.198c.879 0 1.587-.684 1.587-1.538V16.34c0-.854-.708-1.538-1.587-1.538s-1.587.684-1.587 1.538V43.66c0 .855.708 1.539 1.587 1.539z"
        initial={{ opacity: expanded ? 1 : 0 }}
        animate={{ opacity: expanded ? 0 : 1 }}
        transition={{ ease: "easeIn", duration: 0.2 }}
      />
      <path d="M31.587 31.6h11.719a1.6 1.6 0 001.587-1.588 1.6 1.6 0 00-1.587-1.587H16.694a1.6 1.6 0 00-1.587 1.587 1.6 1.6 0 001.587 1.587h14.893z" />
    </g>
  </svg>
);

const NavItem = ({
  title,
  expanded,
  active,
  uri,
  subpages,
  subItem,
  ...props
}) => {
  const Component = uri ? NavLink : NavButton;
  const parent = Array.isArray(subpages) && subpages.length > 0;

  return (
    <Component
      css={[
        S.textButton,
        S.flex,
        S.justifyStart,
        S.alignStart,
        S.w100,
        S.baseText,
        S.navItem,
        active && S.weightBold,
        subItem && S.subNavItem,
      ]}
      onMouseDown={blur}
      uri={!parent ? uri : _get(subpages, "[0].uri")}
      {...props}
    >
      {title}
      {parent && (
        <ParentIcon css={[S.iconSquare, S.mlauto]} expanded={expanded} />
      )}
    </Component>
  );
};

const SubNav = ({ children, expanded, parentId }) => (
  <motion.ul
    key={`subnav-${parentId}`}
    css={[S.baseList, S.block]}
    initial={!expanded}
    animate={expanded ? "open" : "closed"}
    variants={subNavVariants}
  >
    {children}
  </motion.ul>
);

const NavLvl1 = ({ idx, children }) => (
  <motion.li
    initial={{ x: -400, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    transition={{
      ease: "easeInOut",
      duration: 0.4,
      delay: 0.1 * idx,
    }}
    key={idx}
  >
    {children}
  </motion.li>
);

const NavList = ({ pathname, navItems }) => {
  const isActive = (pg) =>
    _get(pg, "active") || `/${_get(pg, "uri")}/` === pathname;

  return (
    <AnimateSharedLayout>
      <ul css={S.baseList}>
        {navItems.map((item, idx) => {
          const subpages = _getArray(item, "subpages");
          const expanded = !!subpages.find(
            ({ uri }) => `/${uri}/` === pathname
          );

          return (
            <NavLvl1 idx={idx} key={idx}>
              <div css={S.relative}>
                <NavItem {...item} expanded={expanded} />
                {isActive(item) && <ActiveMarker />}
              </div>
              {!!subpages.length && (
                <div>
                  <SubNav expanded={expanded}>
                    {subpages.map((subpage, subIdx) => (
                      <li key={`sub-${subIdx}`} css={S.relative}>
                        <motion.div
                          key={`sub-${subIdx}`}
                          variants={subItemVariants}
                          transition={{ delay: 0.1 * subIdx }}
                        >
                          <NavItem {...subpage} subItem />
                        </motion.div>
                        {isActive(subpage) && <ActiveMarker subItem />}
                      </li>
                    ))}
                  </SubNav>
                </div>
              )}
            </NavLvl1>
          );
        })}
      </ul>
    </AnimateSharedLayout>
  );
};

const SideNav = ({ items, Extra }) => {
  const { pathname } = useLocation();
  const { navItems } = useNavData();
  const nav = items || navItems;

  if (!Array.isArray(nav) || !nav.length) {
    return <></>;
  }

  return (
    <div>
      <nav
        data-track-name="side-nav"
        css={[
          S.navRoot,
          S.pinTopGap,
          S.pTopGap,
          S.flex,
          S.flexCol,
          S.navHeight,
          S.sticky,
        ]}
      >
        <NavList pathname={pathname} navItems={nav} />
        {Extra && React.cloneElement(Extra, { css: S.mtauto })}
      </nav>
    </div>
  );
};

export default SideNav;
