import React, { useState, useRef, useEffect } from "react";

import Infobox from "app/components/infoboxes/Infobox";

import usePopupRef from "app/components/infoboxes/usePopupRef";

export const InfoBoxContext = React.createContext();

const InfoBoxProvider = ({ children }) => {
  const buttonRef = useRef();
  const { ref, setOpen, isOpen } = usePopupRef();
  const [infoHtml, setInfoHtml] = useState();
  const [top, setTop] = useState();
  const [left, setLeft] = useState();

  const setPosition = React.useCallback(
    ({ top, left }) => {
      setTop(`${Math.floor(top)}px`);
      setLeft(`${Math.floor(left)}px`);
    },
    [setTop, setLeft]
  );

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const { top, left } = buttonRef.current.getBoundingClientRect();
      setPosition({ top, left });
    }
  }, [isOpen, setPosition]);

  return (
    <InfoBoxContext.Provider
      value={{ buttonRef, ref, setOpen, isOpen, setInfoHtml }}
    >
      {children}
      {isOpen && <Infobox top={top} left={left} html={infoHtml} />}
    </InfoBoxContext.Provider>
  );
};

export default InfoBoxProvider;
