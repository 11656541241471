import React from "react";
import IconInfo from "app/components/icons/IconInfo";
import { blur } from "app/components/Button";

import * as S from "app/components/infoboxes/Infobox.style";

const InfoButton = ({ label, onClick, className }) => (
  <>
    <span
      className={className}
      css={[S.border, S.borderRound, S.relative, S.flexInline, S.alignCenter]}
    >
      <button
        onMouseDown={blur}
        onClick={onClick}
        css={S.hitbox}
        aria-label={label || "More info"}
        data-track-name="info-button"
      />
      <span css={[S.flexCenterAll, S.iconBox]}>
        <IconInfo width={5} height={10} />
      </span>
      {label && (
        <span aria-hidden="true" css={[S.label, S.mt0]}>
          {label}
        </span>
      )}
    </span>
    <span css={S.spacer} />
  </>
);

export default InfoButton;
