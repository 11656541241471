import React, { useEffect } from "react";
// import _get from "lodash.get";
import { useLocation } from "@reach/router";
import { motion, AnimatePresence } from "framer-motion";

import Button from "app/components/Button";
import { ButtonBase } from "app/components/Button.style";
import IconChevron from "app/components/icons/IconChevron";

import usePopupRef from "app/components/infoboxes/usePopupRef";

import * as S from "app/components/HeaderDropdown.style";

const HeaderDropdown = ({ pages }) => {
  const { ref, setOpen, isOpen } = usePopupRef();
  const { pathname } = useLocation();
  const active = pages.find(
    ({ uri }) => pathname === `/${uri}/` || pathname === `/${uri}`
  );

  useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  if (!active) {
    return <></>;
  }

  return (
    <div css={S.relative}>
      <Button
        Component={ButtonBase}
        onClick={() => setOpen(true)}
        className={"active"}
        css={[S.btnFont, S.mx80]}
      >
        {active.title}
        <IconChevron />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div ref={ref} css={[S.absolute, S.flex, S.flexCol, S.popup]}>
              {pages
                .filter(({ id }) => id !== active.id)
                .map(({ uri, title }) => (
                  <Button
                    key={uri}
                    to={`/${uri}/`}
                    label={title}
                    css={S.popupFont}
                    invert
                  />
                ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeaderDropdown;
